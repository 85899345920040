import styles from "./credentialsPage.module.css";
import { useState, useEffect } from "react";
import CourseCard from "../../components/courseCard/CourseCard";
import { Carousel } from "react-bootstrap";
let credentialCategories = [
  {
    key: 0,
    imageSRC: "/images/credentials-page/JS-card.png",
    certification: {
      id: 0,
      available: true,
      title: "JavaScript Professional Developer Online Exam",
      nonMemberPrice: "$999.00",
      learnMore: "/jsexam-details-page",
      description:
        "This exam evaluates your proficiency in working with JavaScript. You will be tested on 'syntax', 'functions', 'controls', 'scope', 'arrays', 'loops', 'iterators', 'objects', ect. Additionally, you will demonstrate your ability to write clean, efficient, and maintainable code using JavaScript.",
      outline: {
        domains: [
          {
            id: 0,
            title: "Syntax",
            description:
              "Understand the basic structure of JavaScript programs, including comments, statements, and expressions.",
          },
          {
            id: 1,
            title: "Functions",
            description:
              "Demonstrate your ability to define and call functions, pass arguments, and return values.",
          },
          {
            id: 2,
            title: "Controls",
            description:
              "Master the use of control structures, including loops, conditionals, and switch statements.",
          },
          {
            id: 3,
            title: "Scope",
            description:
              "Understand the concept of scope and how it affects variable visibility and lifetime.",
          },
          {
            id: 4,
            title: "Arrays",
            description:
              "Learn how to create and manipulate arrays, including adding, removing, and iterating over elements.",
          },
          {
            id: 5,
            title: "Loops",
            description:
              "Understand the different types of loops in JavaScript, including for, while, and do-while loops.",
          },

          {
            id: 6,
            title: "Iterators",
            description:
              "Master the use of iterators and generators to traverse and manipulate data structures.",
          },

          {
            id: 7,
            title: "Objects",
            description:
              "Learn how to create and manipulate objects, including adding, removing, and updating properties.",
          },
        ],
      },
    },
  },

  /**MYSQL Card */
  {
    key: 1,
    imageSRC: "/images/credentials-page/SQL-card.png",
    certification: {
      id: 1,
      title: "MySQL Backend Developer Online Exam",
      available: true,
      nonMemberPrice: "$999.00",
      learnMore: "/mysqlexam-details-page",
      description:
        "This exam evaluates your proficiency in working with MySQL. You will be tested on 'MySQL fundamentals', 'data type and table manipulation', 'data querying with SQL', ect. Additionally, you will demonstrate your ability to manage the MySQL database using the interface application.",
      outline: {
        domains: [
          {
            id: 0,
            title: "MySQL Fundamentals",
            description:
              "Understand the basic structure of MySQL programs, including comments, statements, and expressions.",
          },
          {
            id: 1,
            title: "Data Type and Table Manipulation",
            description:
              "Demonstrate your proficiency in defining and manipulating data types and tables in MySQL.",
          },
          {
            id: 2,
            title: "Data Querying with SQL",
            description:
              "Master the use of SQL queries to retrieve, insert, update, and delete data in MySQL databases.",
          },
          {
            id: 3,
            title: "Database Design",
            description:
              "Understand the principles of database design, including normalization, indexing, and constraints.",
          },
          {
            id: 4,
            title: "MySQL Security and User Management",
            description:
              "Learn how to secure MySQL databases and manage user accounts and permissions.",
          },
          {
            id: 5,
            title: "Backup and Recovery",
            description:
              "Master the use of backup and recovery techniques to protect MySQL databases from data loss.",
          },
          {
            id: 6,
            title: "Stored Procedures and Functions",
            description:
              "Understand how to create and manage stored procedures and functions in MySQL.",
          },
          {
            id: 7,
            title: "Triggers and Events",
            description:
              "Learn how to create and manage triggers and events to automate tasks in MySQL.",
          },
          {
            id: 8,
            title: "Perfomrnace tuning and scaling",
            description:
              "Learn how to optimize the performance of MySQL databases and scale them to handle large volumes of data.",
          },
          {
            id: 9,
            title: "Advanced JSQL Techinques",
            description:
              "Master advanced SQL techniques, including subqueries, joins, and transactions.",
          },
        ],
      },
    },
  },

  /**REACT Card */
  {
    key: 2,
    imageSRC: "/images/credentials-page/REACT-card.png",
    certification: {
      id: 2,
      available: true,
      title: "ReactJS Framework Developer Online Exam",
      nonMemberPrice: "$999.00",
      learnMore: "/reactexam-details-page",
      description:
        "This certification validates the holder’s ability to design and implement robust solutions using ReactJS, a fundamental skill for software development",
        outline: {
          domains: [
            {
              id: 0,
              title: "Basic Structure of React Components",
              description:
                "Understanding how React components are structured. Familiarity with JSX syntax, component lifecycle, and code organization.",
            },
            {
              id: 1,
              title: "State and Props",
              description:
                "Mastery of state management within components. Practical application and passing of props to child components.",
            },
            {
              id: 2,
              title: "Event Handling",
              description:
                "Utilization of event handlers for user interactions. Application of synthetic events to handle events consistently.",
            },
            {
              id: 3,
              title: "Component Lifecycle",
              description:
                "Proper use of lifecycle methods in class components. Understanding the hooks API for functional components.",
            },
            {
              id: 4,
              title: "Hooks",
              description:
                "Creation and use of common hooks like useState and useEffect. Understanding the rules and best practices for using hooks.",
            },
            {
              id: 5,
              title: "Context API",
              description:
                "Utilization of the Context API for state management. Recognizing how and when to use context in a React application.",
            },
            {
              id: 6,
              title: "Rendering and Performance<",
              description:
                "Rendering and Performance. Utilize keys for efficient rendering of lists. Implement memoization techniques to optimize performance.",
            },
            {
              id: 7,
              title: "Routing",
              description:
                "Implement routing using React Router. Manage navigation and dynamic routing in a React application. Handle nested routes and route parameters.",
            },
            {
              id: 8,
              title: "Testing",
              description:
                "Use testing libraries like Jest and React Testing Library. Implement unit tests for React components. Write integration tests for React applications.",
            },
            {
              id: 9,
              title: "Styling",
              description:
                "Style components using CSS-in-JS libraries like styled-components. Apply global styles using CSS or pre-processors like SASS. Use utility-first CSS frameworks like Tailwind CSS.",
            },
          ],
        },
    },
  },

  /**HTML&CSS Card */
  {
    key: 3,
    imageSRC: "/images/credentials-page/HTML-card.png",
    certification: {
      id: 3,
      available: true,
      title: "HTML, CSS Web Designer Online Exam",
      nonMemberPrice: "$999.00",
      learnMore: "/htmlcssexam-details-page",
      description:
        "This certification validates the holder’s ability to design and implement robust solutions using HTML and CSS, fundamental skills for web development",
      outline: {
        domains: [
          {
            id: 0,
            title: "HTML (Hyper Text Markup Language)",
            description:
              "Understanding structure and semantics. The basic structure of an HTNL document (`<!DOCTYPE>`, `<html>`, `<head>`, `<body>`). Identify and utilize semantic HTML5 elements (`<header>`, `<nav>`, `<section>`, `<article>`, `<footer>`, etc.) and when to use them.",
          },
          {
            id: 1,
            title: "Basic Tags",
            description:
              "Implement essential tags. Determine use for class versus functional components. Utilize nesting of components.",
          },
          {
            id: 2,
            title: "Attributes",
            description:
              "Master common attributes such as id, class, src, href, alt, style, title, placeholder, value, type, etc.",
          },
          {
            id: 3,
            title: "Forms",
            description:
              "Create forms using form selectors. Explain and demonstrate form submission processes (method and action attributes).",
          },
          {
            id: 4,
            title: "Links and Images",
            description:
              "Link to other pages or resources using `<a>` tags. Learn to embed images with `<img>` tags and handle alternative text (alt attribute)",
          },
          {
            id: 5,
            title: "Tables",
            description:
              "Learn to use structure tabular data. Creating tables, rows, and columns.",
          },

          {
            id: 6,
            title: "Media",
            description:
              "Learn to embed multimedia content like audio and video (`<audio>`, `<video>`) with fallback options",
          },

          {
            id: 7,
            title: "CSS (Cascading Style Sheets)",
            description:
              "Master selectors and declarations. Learn to identify and apply CSS selectors (element, class, ID, attribute selectors) and declaration blocks. Style elements using properties like color, font-size, margin, padding, background, border, etc.",
          },

          {
            id: 8,
            title: "Box Model",
            description:
              "Learn to explain the concept of the box model (content, padding, border, margin). Adjust box model properties (width, height, box-sizing, etc.)",
          },

          {
            id: 9,
            title: "Layout",
            description:
              "Create basic layouts using CSS (floats, positioning, flexbox, grid). Implement responsive design principles (media queries, fluid layouts).",
          },

          {
            id: 10,
            title: "Typography",
            description:
              "Learn to style text with properties like font-family, font-size, font-weight, text-align, line-height, etc.",
          },

          {
            id: 11,
            title: "Backgrounds and Borders",
            description:
              "Apply background styles (background-color, background-image, background-size, etc. Style borders (border-width, border-style, border-color, border-radius)",
          },

          {
            id: 12,
            title: "Transforms and Transitions",
            description:
              "Learn to add interactivity with CSS transitions (`transition`) and animations keyframes",
          },

          {
            id: 13,
            title: "Flexbox and Grid",
            description:
              "Master utilizing CSS layout systems like Flexbox and Grid for advanced layouts",
          },

          {
            id: 14,
            title: "Responsive Design",
            description:
              "Design websites that adapt to different screen sizes and devices using media queries.",
          },
        ],
      },
    },
  },

  /**TSS Card */
  {
    key: 4,
    imageSRC: "/images/credentials-page/TSS-card.png",
    certification: {
      id: 4,
      available: true,
      title: "Technical Sales Specialist Online Exam",
      nonMemberPrice: "$999.00",
      description:
        "This certification validates the holder’s ability to design and implement robust solutions using technical sales skills, a fundamental skill for software development",
    },
  },
];

function chunkArray(array, size) {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
}

export default function Credentials() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [index, setIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [itemsPerSlide, setItemsPerSlide] = useState(
    window.innerWidth < 768 ? 1 : 1
  );
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setItemsPerSlide(window.innerWidth < 768 ? 1 : 1);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);
  const groupedCategories = chunkArray(credentialCategories, itemsPerSlide);
  let handleSelect = (num) => {
    setIndex(num);
  };
  let handleIndex = (num) => {
    setActiveIndex(num);
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.box}>
          <div className={styles.textGroup}>
            <div className={styles.heading}>
              Transform Your <br />
              Future:
            </div>
            <p className={styles.textWrapper}>
              Become CoITB <br />
              Certified Faster <br />
              Than ever!
            </p>
          </div>
        </div>
        <div className={styles.credentialsPageContent}>
          <div className={styles.credentialsSection}>
            <div className={styles.credentialsPageHeader}>
              <h1>What We Offer</h1>
              <h3>Credential & Certification</h3>
            </div>
            <Carousel
              activeIndex={activeIndex}
              onSelect={handleIndex}
              className={styles.carouselCert}
              variant="dark"
              indicators={false}
              interval={null}
              touch={true}
            >
              {groupedCategories.map((group, index) => (
                <Carousel.Item key={index}>
                  <div className={styles.carouselCards}>
                    {group.map((category) => (
                      <div key={category.key} className={styles.carouselCard}>
                        <CourseCard
                          id={category.key}
                          imageSRC={category.imageSRC}
                          certification={category.certification}
                        />
                      </div>
                    ))}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <div className={styles.credentialsSection}>
            <div className={styles.credentialsPageHeader}>
              <h2>Earn CoITB Official Badges!</h2>
            </div>
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              className={styles.carouselCert}
              variant="dark"
              indicators={false}
              touch={true}
            >
              <Carousel.Item key={0}>
                <div className={styles.carouselItem}>
                  <div className={styles.badgeContainer}>
                    <img
                      src="/images/credentials-page/HTML-Badge.png"
                      alt="badge"
                    />
                  </div>

                  <div className={styles.badgeContainer}>
                    <img
                      src="/images/credentials-page/JS-Badge.png"
                      alt="badge"
                    />
                  </div>
                  <div className={styles.badgeContainer}>
                    <img
                      src="/images/credentials-page/MYSQL-Badge.png"
                      alt="badge"
                    />
                  </div>
                  <div className={styles.badgeContainer}>
                    <img
                      src="/images/credentials-page/React-Badge.png"
                      alt="badge"
                    />
                  </div>
                  <div className={styles.badgeContainer}>
                    <img
                      src="/images/TSS-Badge.png"
                      alt="badge"
                    />
                  </div>
                </div>
              </Carousel.Item>
              {/* <Carousel.Item key={1}>
              <div className={styles.carouselItem}></div>
            </Carousel.Item> */}
            </Carousel>
          </div>
          <div className={styles.credentialsSection}>
            <div className={styles.credentialsPageHeader}>
              <h2>
                Why Choose Certification and Experience Over a Traditional
                Degree?
              </h2>
            </div>
            <div className={styles.certFlex}>
              <div className={styles.certItem}>
                <div
                  className={styles.certIcon}
                  style={{ backgroundColor: "#58C1D8" }}
                >
                  <img src="/images/credentials-page/tag.png" alt="badge" />
                </div>
                <h4>Relevance</h4>
                <p>
                Our credentials stay aligned with the tech industry, ensuring you're certified in the latest in-demand technologies and practices.
                </p>
              </div>
              <div className={styles.certItem}>
                <div
                  className={styles.certIcon}
                  style={{ backgroundColor: "#ED1F27" }}
                >
                  <img src="/images/credentials-page/Timer.png" alt="badge" />
                </div>
                <h4>Speed</h4>
                <p>
                Achieve your career goals faster with our accelerated credentials, bypassing the long commitment of a traditional degree.


                </p>
              </div>
              <div className={styles.certItem}>
                <div
                  className={styles.certIcon}
                  style={{ backgroundColor: "#EEE16D" }}
                >
                  <img src="/images/credentials-page/trophy.png" alt="badge" />
                </div>
                <h4>Experience</h4>
                <p>
                Showcase your real-world skills and experience through a comprehensive examination process.
                </p>
              </div>
              
                
              <div className={styles.certItem}>
                <div
                  className={styles.certIcon}
                  style={{ backgroundColor: "#65A3FE" }}
                >
                  <img src="/images/credentials-page/stars.png" alt="badge" />
                </div>
                <h4>Advantage</h4>
                <p>
                Include your earned badge and certification in your resume and portfolio to highlight your qualifications and expertise.


                </p>
              </div> 
             </div> 
           </div>
        </div>
      </div>
    </>
  );
}
